import moment from "moment";

export function calculateLifeTimeOfDate(date) {
  let time = {
    years: "2",
    months: "3",
    days: "4",
  };
  if (date) {
    const a = moment();
    const b = moment(date);
    var years = a.diff(b, "year");
    b.add(years, "years");

    const noOfDaysInb = b.daysInMonth();
    const noOfDaysIna = a.daysInMonth();
    let months = 0;
    if (noOfDaysInb > noOfDaysIna) {
      months = b.diff(a, "months");
      a.add(months, "months");
    } else {
      months = a.diff(b, "months");
      b.add(months, "months");
    }
    var days = a.diff(b, "days");
    return {
      years: Math.abs(years),
      months: Math.abs(months),
      days: Math.abs(days),
      numberString: `${Math.abs(years)}.${Math.abs(months)}`,
      number: `${Math.abs(years)}.${Math.abs(months)}`,
    };
  }
  return time;
}
