export const validateFingerprintsLevel = (data) => {
  // // ตรวจสอบ fingerprint1 เป็นอย่างแรก
  // if (!data.fingerprint1 || data.fingerprint1.length !== 5) {
  //   return false;
  // }

  for (let i = 2; i <= 6; i++) {
    // เริ่มจาก fingerprint2 เนื่องจาก fingerprint1 ตรวจสอบแล้ว
    const fingerprint = data[`fingerprint${i}`];

    // ถ้า fingerprint ปัจจุบันมีค่า แต่ fingerprint ก่อนหน้าเป็นค่าว่างหรือความยาวไม่ถูกต้อง
    if (
      fingerprint &&
      (!data[`fingerprint${i - 1}`] || data[`fingerprint${i - 1}`].length !== 5)
    ) {
      return false;
    }
  }
  return true; // ถ้าไม่มีกรณีที่ผิดพลาด
};

export const validatesWarningAttendance = (row) => {
  let errors = [];
  //validates
  if (Number(row?.manHourTotalMinute) < 480) {
    errors.push(`เวลาการทำงานไม่ครบ 08:00 ชม.`);
  }
  if (row?.isLateMorning) {
    errors.push(`เข้างานสายเช้า`);
  }
  if (row?.isLateAfternoon) {
    errors.push(`เข้างานสายบ่าย`);
  }
  if (row?.isBreakLate) {
    errors.push(`พักเกิน`);
  }

  let errorMessages = "";
  errors.forEach((error) => {
    errorMessages += error + "\n";
  });
  return {
    status: errors.length > 0 ? false : true,
    errors: errors,
    message: errorMessages,
  };
};
