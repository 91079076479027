import moment from "moment";
import { calculateLifeTimeOfDate } from "../../../../function/supportMoment";
import {
  getFirestoreDataWithMultiQuery,
  getRealtimeData,
} from "../../../../service/firebase";
import { endAt } from "firebase/database";
import { Timestamp } from "firebase/firestore";

export const processLeaveTotal = async (
  attendanceLeaveList = [],
  employeeKey = "",
  leaveType = "leave",
  prepareLeaveList = [],
  startDate = null,
  endDate = null
) => {
  let init = [];
  const response = { status: false, data: [], message: "" };

  const employeeProfile = await getRealtimeData(`Employee/${employeeKey}`);
  if (!employeeProfile.status) {
    response.message = employeeProfile.message;
    return response;
  }

  response.message = "";

  if (!employeeKey) {
    response.message += "employeeKey ";
  }
  if (!employeeProfile?.data?.enterpriseId) {
    response.message += "enterpriseId ";
  }
  if (!employeeProfile?.data?.startJobDate) {
    response.message += "startJobDate ";
  }

  if (response.message) {
    response.message = response.message.trim() + " is required";
    return response;
  }

  //หารายการลาทั้งหมดของพนักงานคนนี้ ทุกเดือนทุกปี ในระบบ
  const totalLeaveInYear = await getLeaveTotalInYear({
    ...employeeProfile?.data,
    employeeKey: employeeKey,
  });

  if (!totalLeaveInYear.status) {
    console.log("error here", totalLeaveInYear);
    response.message = totalLeaveInYear.message;
    return response;
  }

  const currentYear = moment().locale("en").year();

  //loop แทนละประเภทการลา
  attendanceLeaveList = [
    ...attendanceLeaveList,
    {
      id: "absentWork",
      leaveType: "absentWork",
      startUnit: "unlimited",
      endUnit: "unlimited",
      leaveUnit: "unlimited",
      titleLabel: "ขาดงาน",
    },
  ];
  attendanceLeaveList.forEach((leaveMaster) => {
    //loop ตาม การลา และการรับรองทั้งหมด

    if (leaveMaster.leaveType !== leaveType) {
      //ประเภทสิทธิการลา
      return;
    }
    //เช็คอายุงานก่อน
    const jobTime = calculateLifeTimeOfDate(
      employeeProfile?.data?.startJobDate
    );
    const jobTimeYear = jobTime.years;
    const jobTimeMonth = jobTime.months;
    const jobTimeDay = jobTime.days;
    if (leaveMaster.startUnit !== "unlimited") {
      //ถ้าเงื่อนไขอายุเริ่มงาน ไม่เท่ากับ ไม่จำกัด ให้ตรวจสอบเพิ่ม
      if (leaveMaster.startUnit === "year") {
        if (jobTimeYear < leaveMaster.startValue) {
          return;
        }
      } else if (leaveMaster.startUnit === "month") {
        if (jobTimeMonth < leaveMaster.startValue) {
          return;
        }
      } else if (leaveMaster.startUnit === "day") {
        if (jobTimeDay < leaveMaster.startValue) {
          return;
        }
      }
    }
    //เช็คอายุงานสิ้นสุด
    if (leaveMaster.endUnit !== "unlimited") {
      //ถ้าเงื่อนไขอายุเริ่มงาน ไม่เท่ากับ ไม่จำกัด ให้ตรวจสอบเพิ่ม
      if (leaveMaster.endUnit === "year") {
        if (jobTimeYear > leaveMaster.endValue) {
          return;
        }
      } else if (leaveMaster.endUnit === "month") {
        if (jobTimeMonth > leaveMaster.endValue) {
          return;
        }
      } else if (leaveMaster.endUnit === "day") {
        if (jobTimeDay > leaveMaster.endValue) {
          return;
        }
      }
    }
    //ตรวจสอบว่ามีหัวข้อย่อยหรือไม่
    const checkIsHaveSubTitle =
      (
        leaveMaster?.subTitle?.filter((d) => {
          return d.active === "Y";
        }) || []
      ).length > 0;

    //ค้นหาตามหัวข้อ
    let findTotalInTitle = totalLeaveInYear.data.filter((t) => {
      return t.leaveId === leaveMaster.id;
    });
    //หายอดที่ใช้ไป ของปีที่ืเลือก
    let findTotalInTitleYear = findTotalInTitle.filter((t) => {
      if (startDate && endDate) {
        const startOfDay = moment(t.startDate.toDate()).startOf("day");
        const endOfDay = moment(t.endDate.toDate()).utc().endOf("day");
        return (
          startOfDay.isSameOrAfter(startDate) &&
          endOfDay.isSameOrBefore(endDate)
        );
      } else {
        return Number(t.year) === Number(currentYear);
      }
    });
    //ยอดที่กำลังใช้ รอการบันทึก
    let findTotalInPrepare = prepareLeaveList.filter((t) => {
      return t.leaveId === leaveMaster.id;
    });

    //สรุปนาทีที่ใช้ไปทั้งหมด
    let totalMinuteUse = 0; //นาทีที่ใช้ไปทั้งหมด ==> ไว้เช็คเงื่อนไข ตลอดอายุงาน
    let totalMinuteUseCurrentYear = 0; //นาทีที่ใช้ไปในปีปัจจุบัน

    let storeDataForLeaveWithSubTitle = [];

    //ส่วนนี้หาการลาแบบ lifetime
    findTotalInTitle.forEach((t) => {
      totalMinuteUse += t.totalMinute;
      if (t.isSubTitle) {
        storeDataForLeaveWithSubTitle[t.subTitleId] = {
          ...storeDataForLeaveWithSubTitle[t.subTitleId], // รักษาข้อมูลเดิมไว้
          totalMinute:
            (storeDataForLeaveWithSubTitle[t.subTitleId]?.totalMinute || 0) +
            t.totalMinute,
        };
      }
    });

    //ส่วนนี้หาการลาแบบ ปีปัจจุบัน แต่หากมีการ input startDate กับ endDate มาจะใช้ date ใน range นั้น
    findTotalInTitleYear.forEach((t) => {
      totalMinuteUseCurrentYear += t.totalMinute;
      if (t.isSubTitle) {
        storeDataForLeaveWithSubTitle[t.subTitleId] = {
          ...storeDataForLeaveWithSubTitle[t.subTitleId],
          totalMinuteUseCurrentYear:
            (storeDataForLeaveWithSubTitle[t.subTitleId]
              ?.totalMinuteUseCurrentYear || 0) + t.totalMinute,
        };
      }
    });

    // หานาทีที่ใช้ไปในการเตรียมการลา <== ยังไม่ sync ลง database
    findTotalInPrepare.forEach((t) => {
      totalMinuteUse += t.totalMinute;
      totalMinuteUseCurrentYear += t.totalMinute;

      if (t.isSubTitle) {
        storeDataForLeaveWithSubTitle[t.subTitleId] = {
          ...storeDataForLeaveWithSubTitle[t.subTitleId],
          totalMinuteUseCurrentYear:
            (storeDataForLeaveWithSubTitle[t.subTitleId]
              ?.totalMinuteUseCurrentYear || 0) + t.totalMinute,
          totalMinute:
            (storeDataForLeaveWithSubTitle[t.subTitleId]?.totalMinute || 0) +
            t.totalMinute,
        };
      }
    });

    let totalMinuteHave = 0; //นาทีที่มี

    //คำนวนหน่วยที่ใช้ไป
    let cal = {};
    //1.หาจำนวนสิทธิทั้งหมด
    if (leaveMaster.leaveUnit === "unlimited") {
      cal.totalLeaveValue = "ไม่จำกัด";
      totalMinuteHave = null;
      cal.isUnlimited = true;
    } else {
      let totalLeaveMinute = 0;
      cal.isUnlimited = false;
      if (
        leaveMaster.leaveUnit === "day" ||
        leaveMaster.leaveUnit === "dayPerJobTime"
      ) {
        // หาร 3 เพราะชั่วโมงทำงาน 8 ชั่วโมง ต่อวัน
        totalLeaveMinute = (leaveMaster.leaveValue * 1440) / 3;
      } else if (
        leaveMaster.leaveUnit === "month" ||
        leaveMaster.leaveUnit === "monthPerJobTime"
      ) {
        // หาร 3 เพราะชั่วโมงทำงาน 8 ชั่วโมง ต่อวัน
        totalLeaveMinute = (leaveMaster.leaveValue * 43200) / 3;
      }
      totalMinuteHave = totalLeaveMinute;
      cal.totalLeaveValue = formatTime(totalLeaveMinute);
    }

    //2.หายอดที่ใช้
    if (leaveMaster.leaveUnit === "day" || leaveMaster.leaveUnit === "month") {
      cal.totalUseValue = formatTime(totalMinuteUseCurrentYear);
      cal.totalUseMinute = totalMinuteUseCurrentYear || 0;
    } else {
      //lifetime job
      //dayPerJobTime, monthPerJobTime
      cal.totalUseValue = formatTime(totalMinuteUse);
      cal.totalUseMinute = totalMinuteUse || 0;
    }

    let waitForTitleIntegrate = "";
    //3.หายอดคงเหลือ
    if (totalMinuteHave !== null) {
      const reduce =
        leaveMaster.leaveUnit === "day" || leaveMaster.leaveUnit === "month"
          ? totalMinuteUseCurrentYear
          : totalMinuteUse;
      cal.totalMinuteLeft = formatTime(totalMinuteHave - reduce);
      cal.totalMinuteHave = totalMinuteHave;
      cal.totalMinuteLeftNumber = totalMinuteHave - reduce;
      waitForTitleIntegrate = `คงเหลือ : (${formatTimeLabel(
        totalMinuteHave - reduce
      )})  ${
        ["leave", "guarantee", "leaveWithOutPay"].includes(leaveType)
          ? leaveMaster.processManHour
            ? " | *ใช้คำนวน Man Hour"
            : ""
          : ""
      }`;
    } else {
      cal.totalMinuteLeft = "ไม่จำกัด";
      waitForTitleIntegrate = `คงเหลือ : (ไม่จำกัด) ${
        ["leave", "guarantee", "leaveWithOutPay"].includes(leaveType)
          ? leaveMaster.processManHour
            ? " | *ใช้คำนวน Man Hour"
            : ""
          : ""
      }`;
    }

    if (checkIsHaveSubTitle) {
      const subTitle = leaveMaster?.subTitle?.filter((d) => {
        return d.active === "Y";
      });
      subTitle.forEach((sub) => {
        cal.displaySelectLabel = `${sub.label} ${waitForTitleIntegrate}`;
        cal.id = sub.id;
        cal.isSubTitle = true;
        cal.parentId = leaveMaster.id;
        cal.subTitleId = sub.id;

        const findTotalUseInSubTitle = storeDataForLeaveWithSubTitle[sub.id];

        if (findTotalUseInSubTitle) {
          if (
            leaveMaster.leaveUnit === "day" ||
            leaveMaster.leaveUnit === "month"
          ) {
            cal.totalUseValue = formatTime(
              findTotalUseInSubTitle.totalMinuteUseCurrentYear || 0
            );
            cal.totalUseMinute =
              findTotalUseInSubTitle.totalMinuteUseCurrentYear || 0;
          } else {
            //lifetime job
            cal.totalUseValue = formatTime(
              findTotalUseInSubTitle.totalMinuteUse || 0
            );
            cal.totalUseMinute = findTotalUseInSubTitle.totalMinuteUse || 0;
          }
        } else {
          //ไม่มีการใช้สิทธินี้
          cal.totalUseValue = formatTime(0);
          cal.totalUseMinute = 0;
        }

        init.push({ ...leaveMaster, ...cal, subTitleId: sub.id });
      });
    } else {
      cal.displaySelectLabel = `${leaveMaster.titleLabel} ${waitForTitleIntegrate}`;
      init.push({ ...leaveMaster, ...cal });
    }
  });
  response.data = init;
  response.status = true;
  return response;
};

export const formatTime = (minutes) => {
  // Convert minutes to seconds
  const seconds = minutes * 60;

  // Calculate the duration using moment.js
  const duration = moment.duration(seconds, "seconds");

  // Calculate days considering 8 hours as one day
  const totalHours = duration.asHours();
  const days = Math.floor(totalHours / 8);
  const hours = Math.floor(totalHours % 8);
  const minutesPart = duration.minutes();

  // Format the time
  const formattedTime = `${String(days).padStart(2, "0")} | ${String(
    hours
  ).padStart(2, "0")}:${String(minutesPart).padStart(2, "0")}`;

  return formattedTime;
};

const formatTimeLabel = (minutes) => {
  // Convert minutes to seconds
  const seconds = minutes * 60;

  // Calculate the duration using moment.js
  const duration = moment.duration(seconds, "seconds");

  // Calculate days considering 8 hours as one day
  const totalHours = duration.asHours();
  const days = Math.floor(totalHours / 8);
  const hours = Math.floor(totalHours % 8);
  const minutesPart = duration.minutes();

  // Format the time
  const formattedTime = `${String(days).padStart(2, "0")} วัน | ${String(
    hours
  ).padStart(2, "0")}:${String(minutesPart).padStart(2, "0")} ชั่วโมง`;

  return formattedTime;
};

const getLeaveTotalInYear = async (data) => {
  // const currentYear = moment().locale("en").year();

  const query = [
    // ["year", "==", currentYear], เอาออกเพราะ อาจจะมีการลาบางอันใช้ได้แค่จำกัดตลอดอายุงาน
    ["employeeKey", "==", data?.employeeKey],
    // ["enterpriseId", "==", data?.enterpriseId],
    ["status", "==", "Y"],
  ];
  const results = await getFirestoreDataWithMultiQuery("TotalLeave", query);

  return results;
};

export const getLateAttendance = async (employeeKey, startDate, endDate) => {
  const response = { status: false, message: "", data: null };
  if (!employeeKey) {
    response.message = "not found employeeId";
    return response;
  }

  let start = startDate ? startDate : moment().startOf("year");
  let end = endDate ? endDate : moment().endOf("year");

  // สร้าง queries สำหรับ Firestore โดยใช้ช่วงวันที่เดียวกัน (ไม่สนใจเวลา)
  const startOfDay = moment(start).utc().startOf("day").toDate();
  const endOfDay = moment(end).utc().endOf("day").toDate();

  const queries = [
    ["employeeId", "==", employeeKey],
    ["date", ">=", Timestamp.fromDate(startOfDay)],
    ["date", "<=", Timestamp.fromDate(endOfDay)],
    ["status", "==", "Y"],
  ];
  const results = await getFirestoreDataWithMultiQuery(
    `TimeAttendance`,
    queries
  );
  if (!results.status) {
    response.message = results.message;
    return response;
  }

  let filterBreakLate = [];
  results.data.forEach((item) => {
    if (item?.isBreakLate) {
      filterBreakLate.push({
        date: item?.date.toDate() || new Date(),
        dateString: item?.dateString || "",
        breakLateMinute: item?.breakLateMinute || 0,
      });
    }
  });
  let filterLateMorning = [];
  results.data.forEach((item) => {
    if (item?.isLateMorning) {
      filterLateMorning.push({
        date: item?.date.toDate() || new Date(),
        dateString: item?.dateString || "",
        lateMorningMinute: item?.lateMorningMinute || 0,
      });
    }
  });

  response.data = {
    lateMorning: filterLateMorning,
    lateBreak: filterBreakLate,
  };
  response.status = true;
  return response;
};
